// @import "../../styles/variables.scss";

// .btn-box {
//   text-align: center;
// }


.theme-btn{
    position: relative;
    display: inline-block;
    overflow: hidden;
    font-size: 15px;
    height: 50px;
    padding: 12px 80px 12px 40px;
    background: white;
    line-height: 26px;
    color: rgb(0, 0, 0);
    border: 2px solid rgb(207, 202, 211);
    font-weight: 500;
    border-radius: 30px;
    text-transform: capitalize;
    transition: all 500ms ease;
    cursor: pointer;
    z-index: 1;
    text-align: center !important;

  }


  .theme-btn:hover{
    color: #ffffff;
  }
  
  .theme-btn:before {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    content: "";
    background-color: #642e5c;
    border-radius: 30px;
    -webkit-transform: scale(0, 1);
    -ms-transform: scale(0, 1);
    transform: scale(0, 1);
    -webkit-transform-origin: top right;
    -ms-transform-origin: top right;
    transform-origin: top right;
    -webkit-transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -moz-transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -o-transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -webkit-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -moz-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -o-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
  }
  
  .theme-btn:hover:before {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
    -webkit-transform-origin: bottom left;
    -ms-transform-origin: bottom left;
    transform-origin: bottom left;
  }
  
  .theme-btn svg{
    position: absolute;
    top: -1px;
    right: -1px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: #f4db90;
    font-size: 15px;
    color: #fff;
    border-radius: 50%;
    transition: all 900ms ease;
    width: 50px;
    height: 50px;
    line-height: 60px;
  }



  
  .theme-btn:hover svg{
    background: transparent;
  }
  
  .theme-btn-two{
    position: relative;
    display: inline-block;
    overflow: hidden;
    font-size: 16px;
    font-weight: 500;
    color: #dfd4d4;
    background: rgb(213, 194, 229);
    border-radius: 30px;
    text-align: center;
    padding: 10px 30px;
    cursor: pointer;
    z-index: 1;
    transition: all 500ms ease;
  }
  
  .theme-btn-two:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: #fff;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    -webkit-transform: scale(0.2, 1);
    transform: scale(0.2, 1);
    z-index: -1;
  }
  
  .theme-btn-two:hover::before {
    opacity: 1;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  
  .theme-btn-two:hover{
    color: salmon;
    background: #fff;
    box-shadow: 0 0 15px rgba(71, 27, 73, 0.5);
  }


  .btn-box .secondary{
    border: 1px solid salmon !important;

    &::before{
      background-color: salmon!important;
    }
    svg{
      background: salmon!important;
    }
    
  }
  


  @media (max-width: 767.98px){

    .btn-box{

      margin-top: 10%;

     .theme-btn {
      width: 30% !important;
      font-size: 13px;
      padding: 10px;
      text-align: center !important;

     }

    }

    .single{

      max-width: 70%;

      .theme-btn{
        text-align: center;
        
      }
    }
  }


  @media (min-width: 768px) and (max-width: 991.98px){


    
    .btn-box{

      margin-top: 7%;

     .theme-btn {

      width: 30% !important;
      font-size: 15px;
      padding: 10px;
      text-align: center !important;
     }

    }

    .single{

      

      .theme-btn{

        max-width: 50%;
        text-align: center !important;

      }
    }



    

  }
  @media (min-width: 992px) and (max-width: 1198.98px){
    
    .theme-btn{

      max-width: 50%;
      text-align: center !important;

    }
  }
  @media (min-width: 1200px) and (max-width: 1398.98px){
    
    .theme-btn{

      max-width: 50%;
      text-align: center !important;

    }
  }

.cols{
color: black;
}