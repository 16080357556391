.modal {

  .modal-header {
    background-color: #f8f9fa; // Light background for the header
    // border-bottom: 1px solid #dee2e6; // Subtle border
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center; // Center content horizontally
    padding: 20px;

    p {
      margin-bottom: 20px; // Space between text and input fields
      text-align: center; // Center text
      font-size: 1.2rem; // Slightly larger font for better readability
    }

    .otp-input {
      width: 50px; // Fixed width for OTP input
      height: 50px; // Fixed height for consistency
      font-size: 1.5rem; // Larger font for better visibility
      text-align: center; // Center the text within the input
      margin: 5px; // Space between inputs
      border: 2px solid #007bff; // Border color to match theme
      border-radius: 5px; // Rounded corners
      margin: 10px;

      &:focus {
        outline: none; // Remove default outline
        border-color: #0056b3; // Darker border on focus
      }

      &.invalid {
        border-color: red; // Red border for invalid inputs
      }
    }
  }

  .modal-footer {
    display: flex;
    justify-content: space-between; // Space between buttons
    padding: 15px;

    button {
      flex: 1; // Make buttons take equal space
      margin: 0 5px; // Space between buttons
      font-weight: bold; // Bold text for buttons
    }
  }
}


.otp-inputs{
  display: flex;

}
.modal-content{
  margin-top: 500px;
}
.errorText{
  color:red ;
}