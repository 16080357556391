$primary-color:#4e1c47 ;

.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; // Full screen height
  background-color: rgba(0, 0, 0, 0.1); // Subtle background overlay
}

.loader {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(0, 0, 0, 0.1); // Light border
  border-top: 5px solid $primary-color; // Animated color
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

// Keyframes for spinning animation
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
