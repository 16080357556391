.formInput {
    position: relative;
    margin-bottom: 20px;
  }
  
  .labelClass {
    font-weight: bold;
    margin-bottom: 8px;
    display: block;
  }
  
  .inner {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .input {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .invalid {
    border-color: red;
  }
  
  .icon {
    position: absolute;
    right: 10px;
    cursor: pointer;
  }
  
  .errorMessage {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
  .network{
    text-align: center;
  }

  .network-block{
    
  }
  .custom-align{
    text-align: end;
  }