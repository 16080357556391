.pagination {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  
    .page-item {
      background-color: #f0f0f0;
      border: 1px solid #ddd;
      padding: 10px 15px;
      margin: 0 5px;
      font-size: 14px;
      color: #333;
      cursor: pointer;
      border-radius: 5px;
      transition: background-color 0.3s ease, color 0.3s ease;
  
      &:hover {
        background-color: rgb(100, 46, 92);
        color: #fff;
      }
  
      &.active {
        background-color: rgb(100, 46, 92);
        color: #fff;
        border-color: rgb(100, 46, 92);
        pointer-events: none;
      }
    }
  }
  
  .texts{
    text-align: center;
    font-weight: 800;

  }
  .rewards{
    // font-size: 20px;
    font-weight: 800;
  }

  .texts {
    &.processing {
      color: #ff9800; /* Orange for Processing */
    }
    &.initiated {
      color: #2196f3; /* Blue for Initiated */
      
    }
    &.success {
      color: #4caf50; /* Green for Success */
    }
    &.rejected {
      color: #f44336; /* Red for Rejected */
    }
    &.approved {
      color: #8bc34a; /* Light Green for Approved */
    }
    &.failed {
      color: #e91e63; /* Pink for Failed */
    }
    &.hold {
      color: #9e9e9e; /* Gray for Hold */
    }
  }
  