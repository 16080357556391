.modal {
    margin-top: 400px !important;
  
    .modal-header {
      background-color: #f8f9fa; // Light background for the header
      // border-bottom: 1px solid #dee2e6; // Subtle border
    }
  
    .modal-body {
      display: flex;
      flex-direction: column;
      align-items: center; // Center content horizontally
      padding: 20px;
  
      p {
        margin-bottom: 20px; // Space between text and input fields
        text-align: center; // Center text
        font-size: 1.2rem; // Slightly larger font for better readability
      }
  
      .otp-input {
        width: 50px; // Fixed width for OTP input
        height: 50px; // Fixed height for consistency
        font-size: 1.5rem; // Larger font for better visibility
        text-align: center; // Center the text within the input
        margin: 5px; // Space between inputs
        border: 2px solid #007bff; // Border color to match theme
        border-radius: 5px; // Rounded corners
        margin: 10px;
  
        &:focus {
          outline: none; // Remove default outline
          border-color: #0056b3; // Darker border on focus
        }
  
        &.invalid {
          border-color: red; // Red border for invalid inputs
        }
      }
    }
  
    .modal-footer {
      display: flex;
      justify-content: space-between; // Space between buttons
      padding: 15px;
  
      button {
        flex: 1; // Make buttons take equal space
        margin: 0 5px; // Space between buttons
        font-weight: bold; // Bold text for buttons
      }
    }
  }
  
  
  .otp-inputs{
    display: flex;
  
  }
  .modal-content{
    margin-top: 500px;
  }

  .error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
  .wallet-card{
    font-size: 15px;
  }

  @media (max-width:767px){
    .datalist{
      font-size: 10px !important;
    }
    .wallet-card{
      font-size: 11px !important;
    }

    .wallet-font{
      font-size: 10px !important;
    }
  }


  .wallet-font{
    font-size: 13px;
  }
  .wallet-icon{
    font-size: 40px;
  }

  .wallet-icons{
    font-size: 40px;
    color: #4e1c47;
  }
  @media(min-width:1400px) and (max-width: 1849.98px) {

    .datalist{
      font-size: 10px !important;
    }
    .wallet-font{
      font-size: 9px;
    }
    .wallet-icon{
      font-size: 30px !important;
    }
  
  }


  @media(min-width:1200px) and (max-width: 1399.98px) {
    .lists{
      font-size: 8px !important;
    }
    .datalist{
      font-size: 10px !important;
    }
    .wallet-font{
      font-size: 8px !important;
    }
    .wallet-icon{
      font-size: 25px !important;
    }
  }

  @media(min-width:1400px) and (max-width: 1599.98px) {
    .datalist{
      font-size: 10px !important;
    }
    .wallet-font{
      font-size: 7px !important;
    }
    .wallet-icon{
      font-size: 25px !important;
    }
  }


  @media(min-width:768px) and (max-width: 991.98px) {
    .datalist{
      font-size: 10px !important;
    }
    .wallet-font{
      font-size: 10px !important;
    }
  
  }
  
  @media(min-width:992px) and (max-width: 1199.98px) {
    
    .datalist{
      font-size: 10px !important;
    }
    .wallet-font{
      font-size: 15px !important;
    }
  }

.rewards{
 
   
    &.Point-C {
      color: #4caf50; /* Green for Success */
    }
    &.Point-D {
      color: #f44336; /* Red for Rejected */
    }
 
    &.commission-C {
      color: #4caf50; /* Green for Success */
    }
    &.commission-D {
      color: #f44336; /* Red for Rejected */
    }
    &.deposite{
      color: #4caf50; /* Green for Success */
    }
    &.withdraw {
      color: #f44336; /* Red for Rejected */
    } &.Intiated {
      color: #2196f3; /* Blue for Initiated */
    }
    &.Process {
      color: #ff9800; /* Orange for Processing */
      
    }
    &.Captured {
      color: #4caf50; /* Green for Success */
    }
    &.Expired {
      color: #f44336; /* Red for Rejected */
    }
    &.Failed {
      color: #f44336; /* Red for Rejected */
    }
 
    &.Declined {
      color: #f44336; /* Red for Rejected */
    }  &.Hold {
      color: #9e9e9e; /* Gray for Hold */
    }
  
}


.descData{
display: flex;
flex-direction: column;  
}

.pagination {
  display: flex;
  justify-content: center;
  margin: 10px 0;

  .page-item {
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    padding: 10px 15px;
    margin: 0 5px;
    font-size: 14px;
    color: #333;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;

    &:hover {
      background-color: rgb(100, 46, 92);
      color: #fff;
    }

    &.active {
      background-color: rgb(100, 46, 92);
      color: #fff;
      border-color: rgb(100, 46, 92);
      pointer-events: none;
    }
  }
}

.datalist{
  font-size: 12px;
}
.lists{
  font-size: 10px;
}

.equal-height {
  height: 100px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
  }

  &.selected {
    border-color: #388e3c;
    background: #f9f9f9;
  }
}

.wallet-icon {
  font-size: 30px;
  color: #388e3c;
}

.wallet-method-name {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.wallet-method-type {
  font-size: 14px;
  color: #666;
}

.wallet-details {
  font-size: 14px;
  line-height: 1.5;
  color: #444;

  b {
    color: #222;
  }
}
.img-wrap {
  width: 50px;
  height: 50px;
}

.img-wraps {
  width: 80px;
  height: 80px;
}
.bg-head {
  background: #4e1c47 ;
  /* This creates three sections, with the first shade occupying the top 33%, 
     the second shade taking the next 33%, and the final shade covering the last 33% */
}

// .img-wraps {
//   position: relative; /* This ensures the child elements are positioned relative to this container */
// }

// .profile-container {
//   position: relative; /* This is required to position the SVG overlay on top of the image */
// }

.profile-overlay {
  position: absolute;
  top: 80px;  /* Adjust position as needed */
  right: 45px; /* Adjust position as needed */
  z-index: 10;  /* Ensure SVG is above the image */
  width: 40px;
  height: 40px;
  pointer-events: none; /* Allows interactions with underlying elements if needed */
  background-color: white;
  border-radius: 20px;
  padding: 10px;
}

// /* Optional: Adjust avatar size and shape */
// .user-pic {
//   width: 100% !important;
//   height: 100% !important;
//   border-radius: 50%;
// }


.modals {
  margin-top: 100px !important;
  border-radius: 20px;

  .modal-header {
    // background-color: #f8f9fa; // Light background for the header
    // border-bottom: 1px solid #dee2e6; // Subtle border
    border-radius: 10px;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center; // Center content horizontally
    padding: 20px;

    p {
      margin-bottom: 20px; // Space between text and input fields
      text-align: center; // Center text
      font-size: 1.2rem; // Slightly larger font for better readability
    }

    .otp-input {
      width: 50px; // Fixed width for OTP input
      height: 50px; // Fixed height for consistency
      font-size: 1.5rem; // Larger font for better visibility
      text-align: center; // Center the text within the input
      margin: 5px; // Space between inputs
      border: 2px solid #007bff; // Border color to match theme
      border-radius: 5px; // Rounded corners
      margin: 10px;

      &:focus {
        outline: none; // Remove default outline
        border-color: #0056b3; // Darker border on focus
      }

      &.invalid {
        border-color: red; // Red border for invalid inputs
      }
    }
  }

  .modal-footer {
    display: flex;
    justify-content: space-between; // Space between buttons
    padding: 15px;

    button {
      flex: 1; // Make buttons take equal space
      margin: 0 5px; // Space between buttons
      font-weight: bold; // Bold text for buttons
    }
  }
}