
// @import "../../styles/variables.scss";

.container {
    padding: 20px;
    // max-width: 1400px;
    margin: 0 auto;
  // background-color: #252424 ;
      // background: url('../assets/images/bg-team.jpg') no-repeat center center;
      background-size: cover;
      width: 100vw;
      margin-bottom: 10px;
    
    
  }
  
  .header-content-inner {
    // background: rgba(255, 255, 255, 0.12);
border-radius: 16px;
// box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(4.7px);
-webkit-backdrop-filter: blur(4.7px);
// border: 1px solid rgba(255, 255, 255, 0.06);
    text-align: center;
    padding: 40px 0;
    background-color: rgb(63, 33, 61)!important; // Light background color for the header section
  }
  
  .center_side {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  
    .page-title {
      font-size: 2.5rem;
      font-weight: bold;
      color: white; // Dark color for the title
      margin-bottom: 20px;
    }
  
    .intro-text {
      font-size: 25px;
      color: white; // Gray-dark for intro text
      line-height: 1.6;
      margin-bottom: 30px;
    }
  
    .button,
    .button-white {
      display: inline-block;
      padding: 10px 20px;
      font-size: 1rem;
      border-radius: 25px !important;
      text-decoration: none;
      margin: 10px;
      transition: all 0.3s ease;
  
      &.button {
        background-color: rgb(241, 217, 138); // Blue color
        color:white; // White text
        border: none;
  
        &:hover {
          background-color: darken(#007bff, 10%);
        }
      }
  
      &.button-white {
        background-color:white; // White background
        color: #007bff; // Blue text
        border: 1px solid #007bff; // Blue border
  
        &:hover {
          background-color: #007bff; // Blue background
          color: white; // White text
        }
      }
    }
  }
  
  // Responsive adjustments
  @media (max-width: 768px) { // Medium breakpoint (md)
    .page-title {
      font-size: 2rem;
    }
  
    .intro-text {
      font-size: 1rem;
    }
  
    .button, .button-white {
      font-size: 0.875rem;
      padding: 8px 16px;
    }
  }
 
.wrap
{
  position:absolute;
  margin:0 auto;left:0;right:0;
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
  height:100%;
}
.buttons
{
  background:transparent;
  cursor:pointer;
  border: 2px solid white;
  color: white;
  height: 130px;
  width: 130px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 100%;
  text-transform: uppercase;
  font-family: 'Muli-LightItalic', Helvetica;
  font-size: 20px;
  line-height: 30px;
  animation: ripple 1.5s linear infinite;
  transition: all 0.7s ease;


  &:hover
  {
   transform: scale(1.1);
  }
  &:focus
  {
    outline:none;
  }
}

@keyframes ripple {
    0% {
      box-shadow: '0 0 0 0 rgba($color, 0.3)',
      '  0 0 0 1px rgba($color, 0.3)',
       ' 0 0 0 3px rgba($color, 0.3)',
       ' 0 0 0 5px rgba($color, 0.3)';
    }
    100% {
      box-shadow: '0 0 0 0 rgba($color, 0.3)',
        '0 0 0 4px rgba($color, 0.3)',
        '0 0 0 20px rgba($color, 0)',
       ' 0 0 0 30px rgba($color, 0)';
    }
}
