.profileDisplay {
    // background: #f9f9f9; 
    border-radius: 15px; 
    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    padding: 2rem;
    .cardTitle {
      font-size: 1.5rem;
      color: #333;
      text-transform: uppercase;
      margin-bottom: 2rem;
    }
    .profileItem {
      display: flex;
    //   justify-content: space-between;
    gap: 35px;
    
      padding: 10px;
      border-bottom: 1px solid #ddd;
      align-items: center;
      &:last-child {
        border-bottom: none;
      }
      strong {
        font-weight: 600;
        color: #555;
      }
      span {
        font-size: 1rem;
        color: #777;
      }
    }
    .editButton {
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 50px;
      padding: 10px 20px;
      font-size: 1rem;
      cursor: pointer;
      transition: background-color 0.3s ease;
      &:hover {
        background-color: #0056b3;
      }
    }
  }
  
  @media (max-width: 768px) {
    .profileDisplay {
      padding: 1rem;
    }
    .cardTitle {
      font-size: 1.25rem;
    }
    .profileItem {
      font-size: 0.875rem;
    }
  }
  


  