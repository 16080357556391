.avatar-style{
    width: 100% !important;
    height: 60px !important;
    border-radius: 0 !important;
    font-size: 35px !important;
}

.user-pic {
    width: 100% !important;
    height: 100% !important;
}