
.primary-btn{
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid ;
    border-radius: 50px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    outline: none;
    padding: 10px 20px;
    text-decoration: none;
    background-color: #fff;
    color: rgb(68, 18, 78);
    height: 40px;
    width: fit-content;
}