
// blue: #c79f45;
// yellow: #991955;

.header-wrap {
  display: flex;
  justify-content: center;
  nav {
    background-color: #fff;
    // height: 72px;
  }
}

.offcanvabtn {
  color: blue !important;
}
.offcanvabtnscroll {
  color: blue !important;
}

.header-nav-mega {
    margin-top: 25px;

  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  left: 208px;
//   margin: 0;
  opacity: 1;
  pointer-events: all;
//   position: absolute;
  -webkit-transition: opacity 0.1s ease-out;
  transition: opacity 0.1s ease-out;
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;

  li {
    margin-right: 36px;
    padding-bottom: 24px;
    cursor: default;
    list-style-type: none;
    position: relative;

    a {
      font-weight: 500;
      line-height: 20px;
      text-decoration: none;
      color: #000000;
      display: flex;
      font-size: 15px;
      padding-right: 0;
    }

    a.active {
      &::after {
        background-color: blue;
        border-radius: 2px;
        content: "";
        height: 4px;
        left: 0;
        margin-left: 5%;
        position: absolute;
        top: 24px;
        width: 90%;
      }
    }
  }
}

.header-container {
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  word-wrap: break-word;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  position: relative;
  //width: 1120px;
  width: 80%;
  list-style: none;
  margin: 0 auto;
  align-items: center;

  nav {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    word-wrap: break-word;
    box-sizing: border-box;

    .main-nav ul {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      left: 208px;
      margin: 0;
      opacity: 1;
      pointer-events: all;
      position: relative;
      position: absolute;
      top: 24px;
      -webkit-transition: opacity 0.1s ease-out;
      transition: opacity 0.1s ease-out;
      -webkit-transition-delay: 0.25s;
      transition-delay: 0.25s;
    }
  }
}

.header-logo-img {
  height: 20.44px;
  position: absolute;
  top: 24px;
  font-size: 15px;
  line-height: 20px;
}

.side-nav {
  opacity: 1;
  pointer-events: all;
  position: absolute;
  right: 148px;
  top: 0px;
  -webkit-transition: opacity 0.1s ease-out;
  transition: opacity 0.1s ease-out;
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
}

.menu-dropdown {
  left: -236px;
  width: 774px;
  background: #fff;
  border: 1px solid#f7f7f9;
  border-radius: 20px;
  box-shadow: 0 6px 12px rgba(30, 10, 58, 0.04);
  box-sizing: border-box;
  color: blue;
  display: flex;
  line-height: 1;
  margin: 0 auto;
  opacity: 0;
  overflow: hidden;
  padding: 30px 0;
  //pointer-events: none;
  position: absolute;
  right: 0;
  top: 42px;
  transition: opacity 1s ease-out;
  z-index: 9999;
}

.dropdown-link {
  left: -236px;
  //width: 774px;
  width: 620px;
  background: #fff;
  border: 1px solid#f7f7f9;
  border-radius: 20px;
  box-shadow: 0 6px 12px rgba(30, 10, 58, 0.04);
  box-sizing: border-box;
  color: blue;

  line-height: 1;
  margin: 0 auto;
  overflow: hidden;
  padding: 30px 0;
  position: absolute;
  right: 0;
  top: 42px;
  transition: opacity 1s ease-out;
  z-index: 9999;
  //display: flex;
}

.menu-group {
  box-shadow: 1px 0 0 #e7e7f1;
  margin: 0;
  padding: 0 30px !important;

  li:first-of-type {
    padding-bottom: 5px;
    a {
      margin-top: 0;
      color: #121224;
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
    }
  }

  li {
    margin: 0;
    padding: 5px 0;
    color: #000000;
    a {
      color: #474d6a;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
    }
  }
}

.menu-group:last-child {
  box-shadow: none;
}

// .menu-level:hover .menu-dropdown {
//   opacity: 1;
// }

// .menu-dropdown:hover {
//   opacity: 1;
// }

.navbar {
  color: blue !important;
  background-color: yellow;
  position: relative;
}
.navbarscroll {
  color: blue !important;
  background-color: yellow;
  z-index: 999;
  top: 0;
}

.nI-gNb-drawer {
  opacity: 1;
  pointer-events: all;
  position: absolute;
  right: 4px;
  top: 16px;
  -webkit-transition: opacity 0.1s ease-out;
  transition: opacity 0.1s ease-out;
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
}
.nI-gNb-drawer-icon {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  background: #fff;
  border: 1px solid #e7e7f1;
  border: 1px solid #e7e7f1;
  border-radius: 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 42px;
  width: 86px;
}

.nI-gNb-drawer-bars {
  margin-left: 12px;
}

.nI-gNb-bar1 {
  background-color: #979ec2;
  border-radius: 50px;
  height: 2px;
  margin: 3.4px 0;
  width: 20px;
}

.nI-gNb-bar2 {
  background-color: #979ec2;
  border-radius: 50px;
  height: 2px;
  margin: 3.4px 0;
  width: 20px;
}

.nI-gNb-bar3 {
  background-color: #979ec2;
  border-radius: 50px;
  height: 2px;
  margin: 3.4px 0;
  width: 14px;
}

.nI-gNb-drawer-icon-img-wrapper {
  height: 26px;
  margin-left: 18px;
  width: 26px;
}

.nI-gNb-icon-img {
  border-radius: 50%;
  display: block;
  height: 26px;
  overflow: hidden;
  width: 26px;
}

.auth-btns {
  padding-top: 15px;
  display: flex;
  justify-content: flex-end;
  margin-right: 200px;
  gap: 10px;
  margin-top: -70px;

}

.nI-gNb-drawer-icon-img-wrapper {
  position: relative;
}

.profile-info {
  position: absolute;
  top: 55px;
  left: -65px;
  width: 200px;
  height: auto;
  background: #fff;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
  transition: 0.3s;
  //transform: translate(50%, 50%);
  .profile-cover {
    //display: flex;
    margin: 10px auto !important;
    padding-left: 10px;
  }

  h2 {
    font-size: 16px;
    color: blue;
  }

  .link-list {
    padding: 0;
    margin: 0;

    li {
      margin: 5px 0;
      a {
        font-size: 14px;
        color: #000000;
      }
    }
  }
}

.nI-gNb-drawer-icon:hover .profile-info {
  opacity: 1;
  visibility: visible;
}

.menu-group li a {
  &:hover {
    color: yellow !important;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .header-container {
    width: 95%;
  }

  .header-nav-mega li a {
    font-size: 13px;
  }

  .side-nav {
    right: 0;
  }
}

@media (min-width: 1400px) and (max-width: 1499px) {
  .header-container {
    width: 90%;
  }

  .header-nav-mega li a {
    font-size: 13px;
  }

  .side-nav {
    right: 0;
  }
}

@media (min-width: 1500px) and (max-width: 1699px) {
  .header-container {
    width: 80%;
  }

  .header-nav-mega li a {
    font-size: 13px;
  }

  .side-nav {
    right: 0;
  }
}

.header-logo-meg {
  max-width: 175px;
  height: auto;
  a {
    img {
      width: 100%;
      height: auto;
    }
  }
}

.nav-head {
  font-weight: 600 !important;
  color: #000000;
}

.sub-heading-menu {
  background-color: black;
  height: 50px;
}

.navbarSub {
  color: blue !important;
  //background-color: blue;
  position: relative;
}

.navbarSub {
  z-index: 999;
  .main-nav {
    width: 100%;
    ul {
      top: 16px;
      width: 100%;
      justify-content: center;
      left: 0 !important;
      gap: 2%;
      li {
        a {
          color: yellow;
          padding: 0;
          margin-bottom: 0;
          font-size: 14px;
        }
      }
    }
  }
}

// .navbarscrollSub {
//   background-color: black;
//   height: 50px;
//   top: 70px;
//   color: blue !important;

//   .main-nav{

//     ul{
//       top: 16px;
//       width: auto;
//       justify-content: space-between;
//       align-items: center;
//       left: 0;
//       li{
//         a{
//           color: yellow;
//           padding: 0;
//           margin-bottom: 0;
//           font-size: 14px;
//         }
//       }
//     }
//   }
// }

.navbarscrollSub {
  z-index: 999;
  // position: relative;
  background-color: black;
  height: 50px;
  top: 70px;
  color: blue !important;
  //width: 100%;
  justify-content: space-between;

  .main-nav {
    width: 70%;
    ul {
      top: 16px;
      width: 100%;
      justify-content: center;
      gap: 2%;
      position: relative;
      li {
        a {
          color: yellow;
          padding: 0;
          margin-bottom: 0;
          font-size: 14px;
        }
      }
    }
  }
}

.dropdown-link-1 {
  left: -200px;
  //width: 774px;
  width: 620px;
  background: #fff;
  border: 1px solid#f7f7f9;
  border-radius: 20px;
  box-shadow: 0 6px 12px rgba(30, 10, 58, 0.04);
  box-sizing: border-box;
  color: blue;

  line-height: 1;
  margin: 0 auto;
  overflow: hidden;
  padding: 30px 0;
  position: absolute;
  right: 0;
  top: 42px;
  transition: opacity 1s ease-out;
  z-index: 9999;
  //display: flex;
}

// .login-btn {
//   //position: relative;
// }

.login-btn {
  position: relative;
  float: left;
}

.hover-login {
  background-color: #00000082;
  width: 200px;
  height: auto;
  padding: 20px;
  margin: 15px 10px;
  border-radius: 10px;
  position: absolute;
  top: 63%;
  visibility: hidden;
  z-index: 999999;
  ul {
    padding: 0;
    li {
      i {
        padding-right: 10px;
      }
    }
    a {
      padding: 0;
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      line-height: 2;
      color: #fff;

      &:hover {
        color: blue;
      }
    }
  }
}

// .reg-btn {
//   position: relative;
// }

.auth-btns {
//   width: 200px;
}

.login-btn:hover .hover-login {
  visibility: visible;
}

.app-promote {
  //width: 15%;
  flex: 1 1 0px;
  margin-left: 200px;
  justify-content: center;
  display: flex;
  //width: 100%;
  gap: 20px;
  align-items: center;

  p {
    margin: 0;
    color: blue;
    //border: 1px solid black;
    // margin-right: 5%;
    // padding: 2px 20px;
    font-size: 16px;
  }
}


@media (max-width: 1399.98px){

  .hover-reg {
    right: 0%;
  }
}
.header-navs {
  font-size: 14px;
  color: #000000;
  padding: 6px 10px;
  border-radius: 40px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
  display: inline-block; // Required for transform to work properly

  // &:first-child {
  //   color: black;
  // }

  // &:last-child {
  //   color: white;

    // &:hover {
    //   background-color: #3CAEA3;
    // }
  }

  // Slide-in animation
//   &.slidein {
//     animation: slidein 3s ease-in-out;
//   }
// }

// // Slide-in animation keyframes
// @keyframes slidein {
//   from {
//     margin-left: 100%;
//     width: 300%;
//   }

//   75% {
//     font-size: 300%;
//     margin-left: 25%;
//     width: 150%;
//   }

//   to {
//     margin-left: 0%;
//     width: 100%;
//   }
// }

// // Additional styles for elements with .app-promote class
.app-promote {
  .slidein {
    opacity: 1;
    visibility: visible;
    list-style: none;
    transition: 0.3s;
  }
}
